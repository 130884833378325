<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-13 10:59:08
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-30 14:28:35
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/MediaReport/MediaChart/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="media-report-chart-box">
    <span class="expend-btn" @click="handleChangeExpend"><a-icon class="icon" :type="isExpend ? 'up' : 'down'" />展开</span>
    <div class="media-report-chart" v-if="isExpend">
      <div class="chart">
        <MediaChart ref="chart" :target="currentChecked[0]"/>
      </div>
      <div class="targets">
        <div class="title">指标</div>
        <a-menu :selectedKeys="currentChecked" type="inner" class="menu_checked" @select="handleChecked">
          <a-menu-item class="menu_item" v-for="item in targets" :key="item.id">
            <div :title="item.name">
              <span>{{ item.name }}</span>
            </div>
          </a-menu-item>
        </a-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { targets, MediaChart } from '@/components/MediaChart'
export default {
  name: 'MediaReportChart',
  components: { MediaChart },
  data () {
    return {
      isExpend: false,
      targets,
      currentChecked: [targets[0].id],
      query: {}
    }
  },
  methods: {
    handleChangeExpend () {
      this.isExpend = !this.isExpend
      if (!this.isExpend) {
        return
      }
      this.$nextTick(() => {
        this.$refs.chart.getChartData(this.query)
      })
    },
    handleChecked ({ key }) {
      this.currentChecked = [key]
    },
    handleSearch (query) {
      this.query = query
      if (this.isExpend) {
        this.$nextTick(() => {
          this.$refs.chart.getChartData(query)
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.media-report-chart-box{
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: @smallMargin;
  border-radius: @mediumRadius;
  border: 1px solid @modelBorderColor;
  box-shadow: @modelShadow;

}
.expend-btn{
  cursor: pointer;
  color: @primary-color;
  .icon{
    margin-right: 5px;
  }
}
.media-report-chart {
  display: flex;
  .chart{
    flex: 1 auto;
    position: relative;
  }
  .targets{
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    max-width: 180px;
    width: 20%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    .ant-menu-vertical {
      border-right: none;
    }
    .title {
      height: 30px;
      line-height: 30px;
      padding-left: 10px;
      background: #eee;
    }
    .menu_checked{
      overflow-y: auto;
      flex-grow: 1;
      .menu_item {
      background: fade(@primary-color, 2%);
      height: 29px;
      line-height: 29px;
      font-size: 13px;
      margin: 3px;
      border-left: 3px solid #fff;
      &.ant-menu-item-selected {
        border-left: 3px solid;
        background: fade(@primary-color, 10%);
      }
    }
    }
  }
}
</style>
