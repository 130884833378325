<template>
  <div class="synth-search-container">
    <div class="search_wrapper">
      <a-range-picker
        class="search_item"
        format="YYYY-MM-DD"
        :placeholder="['Start', 'End']"
        @change="ChangeDate"
        :value="rangePickerDate"
        :allowClear="false"
        :ranges="{
          今日: [initDate(0, 0).start, initDate(0, 0).end],
          昨日: [initDate(1, 1).start, initDate(1, 1).end],
          最近一周: [initDate(6, 0).start, initDate(6, 0).end],
          最近一个月: [initDate(30, 0).start, initDate(30, 0).end],
          上一个月: getLastMonthDuration()
        }"
        :disabledDate="disabledDate"
      >
      </a-range-picker>
      <m-select-more
        class="search_item"
        v-model="searchquery.userIdList"
        :allData="adminUserList"
        :searchById="true"
        :hasIcon="false"
        :showId="false"
        label="账户"
        :showSelectedPart="true"
        :hasSearch="true"
        width="450px"
        :canSelectAll="true"
        @change="handleChangeUser"
        v-if="role === 'SUPER_ADMIN'"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.publisherIdList"
        :allData="supplierList"
        :searchById="true"
        :hasIcon="true"
        :showId="true"
        width="450px"
        label="供应商"
        @change="changeSupplier"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.placeIdList"
        :allData="placeList"
        :searchById="true"
        :hasIcon="true"
        :showId="true"
        width="450px"
        label="广告位"
        @change="changePlaceIds"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.dspPlaceIdList"
        :allData="dspList"
        :searchById="true"
        :hasIcon="false"
        :showId="true"
        label="广告源"
        :showSelectedPart="true"
        width="450px"
        :canSelectAll="true"
        @change="handleAutoSearch"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.positionIdList"
        :allData="positionList"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        label="广告样式"
        :hasSearch="false"
        :showSelectedPart="true"
        width="450px"
        :canSelectAll="true"
        @change="handleAutoSearch"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.platIdList"
        :allData="platList"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        label="广告平台"
        :showSelectedPart="true"
        width="450px"
        :canSelectAll="true"
        @change="changePlat"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.platAccountIdList"
        :allData="platAccountList"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        label="平台账号"
        :showSelectedPart="true"
        :hasSearch="true"
        width="450px"
        :showLabel="true"
        :canSelectAll="true"
        @change="handleAutoSearch"
      />
      <m-select-more
        class="search_item"
        v-model="searchquery.osIdList"
        :allData="osList"
        :searchById="false"
        :hasIcon="false"
        :hasSearch="false"
        :showId="false"
        label="操作系统"
        :showSelectedPart="false"
        width="260px"
        :canSelectAll="false"
        @change="handleAutoSearch"
      />
      <m-select-tree class="search_item" label="地区" :options="areaTreeList" v-model="searchquery.countryCodeList" @change="handleAutoSearch"/>
      <m-select-more
        v-if="+cur === 0"
        class="search_item"
        v-model="searchquery.bundleList"
        :allData="trafficBundleList"
        :searchById="true"
        :hasIcon="true"
        :showId="true"
        label="应用包名"
        :hasSearch="true"
        :showSelectedPart="true"
        width="450px"
        :canSelectAll="true"
        @change="handleAutoSearch"
      />
      <div class="search_item" v-else>
        <a-input
          type="text"
          v-model="searchquery.bundleName"
          placeholder="请输入应用包名"
          @change="handleAutoSearch"></a-input>
      </div>
      <m-select-more
        class="search_item"
        v-model="searchquery.taskList"
        :allData="dspTaskList"
        :searchById="true"
        :hasIcon="false"
        :showId="false"
        label="任务"
        :showSelectedPart="true"
        width="450px"
        :canSelectAll="true"
        @change="handleAutoSearch"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getListForManage'
import { getDspList, getRtbPlatAccounts } from '@/apiForManage/common'
import { getDuration } from '@/utils/duration'
export default {
  mixins: [mixDate, mixGetList],
  data () {
    return {
      searchquery: {
        dateEnd: '',
        dateStart: '',
        userIdList: [],
        publisherIdList: [],
        placeIdList: [],
        dspPlaceIdList: [],
        osIdList: [],
        positionIdList: [],
        platAccountIdList: [],
        platIdList: [],
        countryCodeList: [],
        bundleList: [],
        bundleName: undefined,
        taskList: []
      },
      osList: [
        { name: '安卓', id: '1' },
        { name: 'IOS', id: '0' }
      ],
      dspList: [],
      platAccountList: [],
      timer: null
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      cur: (state) => state.user.cur
    })
  },
  async created () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(0, 0)
    this.rangePickerDate = [start, end]
    this.searchquery.dateEnd = endDate
    this.searchquery.dateStart = startDate
    this.role === 'SUPER_ADMIN' && await this.getAdminUserList()
    await this.getSupplierList(this.searchquery.userIdList)
    await this.getPlaceList()
    await this.getDspList()
    this.getPositionList()
    this.getAreaTreeList()
    this.cur === 0 && this.getTrafficBundleList()
    this.dealRouteQuery()
    await this.getPlatListByuser()
    await this.getRtbPlatAccounts()
    this.getDspTaskList()
    // 进入时搜索
    this.$emit('handleAutoSearch', this.searchquery)
  },
  methods: {
    async handleChangeUser () {
      await this.getSupplierList(this.searchquery.userIdList)
      this.changeSupplier()
    },
    // 获取广告平台账户下拉
    async getRtbPlatAccounts () {
      const { data = [] } = await getRtbPlatAccounts({
        platIdList: this.searchquery.platIdList
      })
      this.platAccountList = data
    },
    changeQuery (template) {
      const { query, timeRange = [0, 0] } = template
      this.searchquery = {
        ...this.searchquery,
        ...query
      }
      const { endDate, startDate, end, start } = this.initDate(timeRange[0], timeRange[1])
      this.rangePickerDate = [start, end]
      this.searchquery.dateEnd = endDate
      this.searchquery.dateStart = startDate
      this.handleAutoSearch()
      this.searchquery.groupByList = undefined
    },
    dealRouteQuery () {
      const { query = {} } = this.$route
      const { placeId, publisherId, dspId } = query
      placeId && (this.searchquery.placeIdList = [placeId])
      publisherId && (this.searchquery.publisherIdList = [publisherId])
      dspId && (this.searchquery.dspPlaceIdList = [dspId])
    },
    async getDspList (platIds = []) {
      const { data = [] } = await getDspList({
        platAccountIdList: [],
        platIdList: platIds
      })
      data.forEach((item) => {
        item.searchId = item.platPlaceId
        item.id = '' + item.id
      })
      this.dspList = data
    },
    async changePlat (ids) {
      await this.getDspList(ids)
      await this.getRtbPlatAccounts()
      this.handleAutoSearch()
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
      this.handleAutoSearch()
    },
    // 应用改变查广告位
    async changeSupplier (ids) {
      await this.getPlaceList(ids)
      this.changePlaceIds(this.searchquery.placeIdList)
    },
    async changePlaceIds (ids) {
      this.handleAutoSearch()
    },
    handleAutoSearch () {
      this.$emit('handleAutoSearch', this.searchquery)
    }
  }
}
</script>

<style lang="less" scoped>
.synth-search-container {
  .search_wrapper {
    box-sizing: border-box;
    margin-bottom: calc(@smallMargin - 8px);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: @compMargin;
  }
}
</style>
